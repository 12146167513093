<template>
  <div class="protokoll-tageskalender">
    <h5 class="font-weight-bold" style="font-size: 1rem;">Tageskalender</h5>
    <TageskalenderBoolean v-model="tageskalender" form-label="Sollen die Arbeitszeiten im Tageskalender berücksichtigt werden?" />
  </div>
</template>

<script>
import TageskalenderBoolean from '@/components/FormElements/Boolean'

export default {
  name: 'ProtokollTageskalender',
  components: {
    TageskalenderBoolean
  },
  data () {
    return {
      tageskalender: true
    }
  },
  watch: {
    tageskalender: function () {
      this.$emit('input', this.tageskalender)
    }
  },
  methods: {
  }
}
</script>
