<template>
  <div class="protokoll-datum">

    <strong>Datum*</strong>
    <StatusDate v-model="datum" form-label-class="d-none" />

  </div>
</template>

<script>
import StatusDate from '@/components/FormElements/Date'

export default {
  name: 'ProtokollStatus',
  components: {
    StatusDate
  },
  computed: {
  },
  mounted () {
    this.datum = new Date()
  },
  data () {
    return {
      datum: null
    }
  },
  watch: {
    datum: function () {
      this.$emit('input', this.datum)
    }
  },
  methods: {
  }
}
</script>
